.container {
    width: 100%;
    background: linear-gradient(100deg, rgb(18, 18, 18) 0%, rgb(0, 23, 52) 100%);
    box-shadow: rgb(0 0 0 / 60%) -8px 20px 14px -17px inset;
}

.inner {
    width: 100%;
    max-width: var(--container-width);
    padding: 70px 24px 90px 24px;
    margin: 0 auto;
}

.title {
    margin: 0;
    line-height: 1;
    font-family: var(--title-font-family);
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-size: 62px;
    letter-spacing: 0.02em;
    text-shadow: 2px 2px 4px RGB(0, 68, 80);
}

.images {
    margin-top: 68px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    grid-gap: 32px;
    > div {
        width: 145px;
        height: 50px;
    }
    .pressLogo {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}