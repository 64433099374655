.container {
    overflow: hidden;
    width: 100%;
    background: linear-gradient(-180deg, rgb(43, 148, 171), rgb(88, 72, 219));
    box-shadow: 4px 4px 27px -3px #191919;
    border-radius: 10px;
    padding: 36px 48px;
    a {
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
}


.releaseTitle {
    font-size: 32px;
    font-family: var(--title-font-family);
    line-height: 1;
    letter-spacing: 0.01em;
    color: #fff;
    text-align: center;
}

.releaseDescription {
    font-size: 12px;
    text-align: center;
}

.countDown {
    text-align: center;
    margin: 16px 0;
    font-size: 57px;
    font-family: var(--title-font-family);
    line-height: 1;
    letter-spacing: 0.01em;
    color: #fff;
}

@media (max-width: 680px) {
    .container {
        padding: 24px 24px;

    }
    .releaseTitle {
        font-size: 24px;
    }
    .countDown {
        font-size: 32px;
    }
}