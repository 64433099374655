.container {
    position: relative;
    height: 505px;
    width: 100%;
    background: #000;
    padding: 0 44px;
    overflow: hidden;
}

.decoration {
    position: absolute;
    bottom: -250px;
    right: -190px;
    max-width: 650px;
    width: 100%;
    height: 650px;
    z-index: 1;
    background-image: url('/images/blurred_logo.png');
    background-repeat: no-repeat;
    background-size: contain;
    pointer-events: none;
}

.left .decoration {
    bottom: -250px;
    left: -190px;
    max-width: 650px;
    width: 100%;
    height: 650px;
    z-index: 1;
    background-image: url('/images/blurred_logo_2.png');
}
.right .decoration {
    bottom: -250px;
    right: -190px;
    max-width: 650px;
    width: 100%;
    height: 650px;
    z-index: 1;
    background-image: url('/images/blurred_logo.png');
}

.align {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: var(--container-width);
}

.image {
    position: absolute;
    top: 0;
    width: 75%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
    max-width: 875px;
}

.gradient {
    position: absolute;
    z-index: 1;
    top: 0;
    height: 100%;
    width: 75%;
    max-width: 875px;
}

.innerContainer {
    z-index: 2;
    max-width: 420px;    
    width: 100%;
}

.title {
    margin: 0;
    line-height: 1;
    font-family: var(--title-font-family);
    color: rgba(62, 185, 216);
    text-transform: uppercase;
    font-size: 62px;
    letter-spacing: 0.02em;
    text-shadow: 0 0 2px #115585;
}

.description {
    margin: 0;
    margin-top: 24px;
    font-family: var(--title-font-text);
    color: #fff;
    font-weight: 400;
    font-size: 18px;
}

.button {
    margin-top: 24px;
    text-transform: uppercase;
}

.left {
    .container {
        background: linear-gradient(90deg, RGBA(19, 19, 19, 1) 0%, RGB(0, 0, 0) 100%);
    }
    .image {
        right: 0;
    }
    .innerContainer {
        margin-right: auto;
    }
    .gradient {
        background: linear-gradient(90deg, RGB(0, 0, 0) 0%, RGBA(0, 0, 0, 0.15) 60%, RGBA(255, 255, 255, 0.1) 100%);
        right: 0;
    }
}   

.right {
    .container {
        background: linear-gradient(90deg, RGB(0, 0, 0) 0%, RGBA(19, 19, 19, 1) 100%);
    }
    .image {
        left: 0;
    }
    .innerContainer {
        margin-left: auto;
    }
    .gradient {
        background: linear-gradient(-90deg, RGB(0, 0, 0) 0%, RGBA(0, 0, 0, 0.15) 60%, RGBA(255, 255, 255, 0.1) 100%);
        left: 0;
    }
}

@media (max-width: 480px) {
    .gradient, .image {
        width: 100%;
    }
    .gradient {
        background: rgba(0, 0, 0, 0.5)!important;
    }
    .description {
        text-shadow: 1px 1px 1px #000;
    }
    .decoration {
        display: none;
    }
}