.container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 625px;
    padding: 48px 0;
    background-image: url('/images/investors_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.banner {
    overflow: hidden;
    max-width: var(--container-width);
    background: linear-gradient(180deg, rgb(43, 148, 171), rgb(88, 72, 219));
    box-shadow: 4px 4px 27px -3px #191919;
    border-radius: 10px;
}

.inner {
    padding: 48px 48px 0 48px;
}

.title {
    margin: 0;
    line-height: 1;
    font-family: var(--title-font-family);
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-size: 62px;
    letter-spacing: 0.02em;
    text-shadow: 2px 2px 4px RGB(0, 68, 80);
}

.mainInvestors {
    margin-top: 48px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-content: center;
    grid-gap: 80px;
    @media (max-width: 680px) {
        grid-template-columns: auto;
    }
}

.mainInvestorImage {
    width: 200px;
    height: 70px;
    object-fit: contain;
    margin: 0 auto;
}

.carousel {
    margin-top: 24px;
    position: relative;
    padding: 48px 0;
    overflow: hidden;
}

.slider {
    display: grid;
    grid-gap: 48px;
    grid-auto-flow: column;  
    will-change: transform;
    animation: slide 50s linear infinite;
    > *:first-child {
        margin-left: 48px;
    }
    
    > *:last-child {
        margin-right: 48px;
    }
}

@keyframes slide {
    100% {
        transform: translateX(-3287px);
    }
}

.carouselImage {
    display: flex;
    width: 125px;
    height: 50px;
    align-items: center;
    justify-content: center;
    span {
        display: block;;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}