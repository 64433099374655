.container {
	display: block;
	width: 24px;
	height: 100px;
    transform: scale(0.6);
    opacity: 0.8;
}

.arrow1,.arrow2,.arrow3 {
  display: block;
  width: 5px;
  height: 5px;
  transform: rotate(45deg);
   
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  margin: 0 0 3px 4px;
  
  width: 16px;
  height: 16px;
}


.arrow1 {
  margin-top: 1px;
}

.arrow1, .arrow2, .arrow3 {
    animation: mouse-scroll 2s infinite;
}

.arrow1 {
  animation-direction: alternate;
  animation-delay: alternate;
}

.arrow2 {
  animation-delay: .4s;
  animation-direction: alternate;
  margin-top: -6px;
}

.arrow3 {
  animation-delay: .6s;
  animation-direction: alternate;
  margin-top: -6px;
}

.mouse {
  height: 42px;
  width: 24px;
  border-radius: 14px;
  transform: none;
  border: 2px solid white;
  top: 170px;
}

.wheel {
    height: 5px;
    width: 2px;
    display: block;
    margin: 5px auto;
    background: white;
    position: relative;

    height: 4px;
    width: 4px;
    border: 2px solid #fff;
    border-radius: 8px;
}

.wheel {
  animation: mouse-wheel 1.6s linear infinite;
}

@keyframes mouse-wheel {
   0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(6px);
  }
}

@keyframes mouse-scroll {
  0%   { opacity: 0; }
  50%  { opacity: .5; }
  100% { opacity: 1; }
}