.container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    background-image: url('/images/models_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 48px 0;
}

.inner {
    overflow: hidden;
    max-width: var(--container-width);
    width: 100%;
    background: linear-gradient(180deg, rgb(43, 148, 171), rgb(88, 72, 219));
    box-shadow: 4px 4px 27px -3px #191919;
    border-radius: 10px;
    padding: 48px;
    
    > div {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: 24px;
        margin-top: 48px;
        align-items: flex-start;
        @media (max-width: 680px) {
            grid-template-columns: auto;
            *:first-child {order: 2;}
            *:last-child {order: 1;}
        }
    }
}

.title {
    margin: 0;
    line-height: 1;
    font-family: var(--title-font-family);
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-size: 62px;
    letter-spacing: 0.02em;
    text-shadow: 2px 2px 4px RGB(0, 68, 80);
}

.models {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 8px;
    @media (max-width: 680px) {
        grid-template-columns: 1fr 1fr;
    }
}

.model {
    width: 75px;
    height: 75px;
    padding: 8px;
    background-color: rgba(255,255,255, 0.28);
    border-radius: 5px;
    box-shadow: 2px 2px 4px rgba(0,0,0,0.2);
    transition: background-color 0.225s ease-in-out;
    cursor: pointer;
    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
    &:hover {
        background-color: rgba(255,255,255, 0.6);
    }
    @media (max-width: 680px) {
        width: 100%;
    }
}

.modelViewer {
    display: flex;
    align-items: center;
    justify-content: center;
    > * {
        width: 100%;
    }
}