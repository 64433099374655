.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color 0.255s ease-in-out;
    background-color: transparent;

    @media (max-width: 680px) {
        flex-direction: column;
        width: 100vw;
        max-width: 100vw!important;
        position: fixed;
        transform: translateZ(1px);
        left: 0;
        top: 0;
        padding: 24px;
        z-index: 9999;
        &.isOpen {
            height: 100vh;
            &.isFloating {
                height: calc(100vh - 24px);
            }
            background-color: #0a0e32bd;
        }
        &.isScrolled {
            background-color: #0a0e32bd;
        }
    }
    
    &.isFloating {
        width: calc(100vw - 48px);
        padding: 14px;
        @media (min-width: 681px) {
            position: absolute;
            margin: 0 auto;
            left: 24px;
            top: 24px;
            padding: 14px 24px;
        }
        z-index: 1001;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 12px;
        @media (max-width: 680px) {
            width: calc(100vw - 24px);
            left: 12px;
            top: 12px;
        }
    }
}

.mobileButton {
    display: none;
    border: 0;
    padding: 8px;
    background-color: transparent;
    text-decoration: underline;
    color: #fff;
    font-family: 'Bebas Neue';
    font-size: 18px;
     @media (max-width: 680px) {
        display: block;
    }
}


.logoContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.logo {
    display: block;
    max-width: 130px;
    width: 100%;
    height: 74px;
    img {
        display: block;
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
    @media (max-width: 680px) {
        height: 46px;
    }
}

.nav {
    display: flex;
    grid-gap: 48px;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    @media (max-width: 940px) {
    }
    @media (max-width: 940px) {
        grid-gap: 24px;
    }
    @media (max-width: 680px) {
        display: none;
        margin-top: 24px;
        flex-direction: column;
        justify-content: space-around;
    }
}

.isOpen .nav {
    @media (max-width: 680px) {
        display: flex;
    }
}


.navLink {
    position: relative;
    font-size: 20px;
    font-family: var(--title-font-family);
    line-height: 1;
    letter-spacing: 0.01em;
    color: #fff;
    white-space: nowrap;

    @media (max-width: 840px) {
        font-size: 18px;
    }

    &:hover:not(.popover) {
        text-decoration: underline;
    }
    &.popover {
        cursor: default;
    }

    &.popover span {
        position: absolute;
        border-radius: 8px;
        line-height: 1;;
        left: 50%;
        top: calc(100% + 8px);
        transform: translateX(-50%);
        width: fit-content;
        padding: 10px 12px 8px 12px;
        background: rgba(0,0,0,0.7);
        color: #fff;
        font-size: 16px;
        letter-spacing: 0.01em;
        text-transform: lowercase;
        white-space: nowrap;
        
        opacity: 0;
        pointer-events: none;
        transition: all 0.225s ease-in-out;
        
        &:after {
            bottom: 100%;
            left: 50%;
            border: solid transparent;
            content: "";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }
        
        &:after {
            border-color: rgba(0, 0, 0, 0);
            border-bottom-color: rgba(0,0,0,0.7);
            border-width: 4px;
            margin-left: -4px;
        }
    }

    &:hover {
        &.popover span {
            opacity: 1;
        }   
    }   
}

.isOpen .navLink {
    font-size: 32px;
}
