.container {
    position: relative;
    width: 100%;
    height: 100vh;
    min-height: 780px;
    background-color: #000;
    display: flex;
    flex-direction: column;
    padding: 8px;
    z-index: 1000;
}

.header {
    flex: 0;
    padding-top: 20px;
    max-width: var(--container-width);
    width: 100%;
    margin: 0 auto;
    z-index: 9999;
}

.video {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1;
    opacity: 0.6;
}

.inner {
    flex: 1;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
        max-width: var(--container-width);
        width: 100%;
        margin-top: -90px;
    }
}

.title {
    margin: 0;
    line-height: 1;
    font-family: var(--title-font-family);
    color: #fff;
    text-transform: uppercase;
    font-size: 62px;
    letter-spacing: 0.02em;
    text-shadow: 1px 1px 3px #000;
    @media (max-width: 680px) {
        font-size: 48px;
    }
}

.subTitle {
    margin: 0;
    margin-top: 24px;
    font-family: var(--title-font-text);
    color: #fff;
    font-weight: 400;
    font-size: 26px;
    text-shadow: 1px 0px 3px #000;
    @media (max-width: 680px) {
        margin-top: 18px;
        font-size: 18px;
    }
}

.info {
    display: flex;
    justify-content: space-between;
    grid-gap: 24px;
    
    > div:first-child {
        flex: 1.5;
    }
    
    > div:last-child {
        flex: 1;
        display: flex;
        justify-content: flex-end;
    }
    @media (max-width: 680px) {
        flex-direction: column;
    }
}


.socials {
    margin-top: 24px;
    display: flex;
    grid-gap: 12px;
    > a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        height: 45px;
        background-color: #fff;
        border-radius: 50%;
        box-shadow: 1px 0px 3px #000;
        path {
            fill: #1B063F;
        }
    }
}
.twitter {
    width: 24px;
}
.medium {
    width: 24px;
}
.telegram {
    position: relative;
    left: -1px;
    width: 24px;
}
.discord {
    width: 24px;
}

.indicator {
    position: absolute;
    bottom: -18px;
    right: 50%;
    margin-left: -12px;
}